// import code for every page

import '@/global';
import '@/components/sw';

// import code by section presence
// section should have data-js-import="name" attribute

const sections = [
  'header-navigation',
  'page-faqs',
  'vps-locations',
  'testimonials',
  'contact-section'
];

sections
  .filter((name) => document.querySelector(`section[data-js-import="${name}"]`))
  .forEach((name) => {
    import(
      // /* webpackChunkName: "forexvpsChunk" */
      `@/sections/${name}`
    );
  });
