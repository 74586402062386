export function loadSvg(url, container = null) {
  const req = new XMLHttpRequest();

  req.onload = function (e) {
    let svg = document.implementation.createHTMLDocument('');

    svg.body.innerHTML = e.target.response;

    if (!container) {
      container = document.createElement('div');

      container.style.position = 'absolute';
      container.style.width = 0;
      container.style.height = 0;
      container.style.overflow = 'hidden';
      container.style.zIndex = -9999;

      document.body.appendChild(container);
    }

    container.appendChild(svg.body.firstElementChild);

    svg = null;
  };

  req.open('GET', url, true);
  req.responseType = 'text';
  req.send();
}
