import Cookies from 'js-cookie'; // https://github.com/js-cookie/js-cookie#basic-usage
import { emitter } from '@/helpers/emitter';
import { cookieConsentCookieName } from '@/core/defaults';

const consent = document.querySelector('.js-cookie-consent');

if (consent && !Cookies.get(cookieConsentCookieName)) {
  const btn = consent.querySelector('.js-cookie-consent-ok');

  btn.addEventListener('click', () => {
    Cookies.set(cookieConsentCookieName, 'ok', { expires: 365 });
    consent.style.display = 'none';
    emitter.emit('cookieConsent:hide');
  });

  setTimeout(() => {
    consent.classList.add('visible');
  }, 1500);
}
