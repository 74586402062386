// import code for every page

import { loadSvg } from '@/core/utils';
import '@/helpers/scrollOut';
import '@/components/cookieConsent';
import { lazyLoadVideo } from '@/helpers/dom';

// load svg sprite

document.addEventListener('DOMContentLoaded', () => {
  loadSvg(thApp.svgSpriteUrl);

  const themeToggleBtn = document.querySelectorAll('.js-theme-btn');
  const themeToggle = document.querySelectorAll('.js-theme-toggle');
  const rootElement = document.documentElement;

  // Decide on initial theme
  // If the page is forced dark, set dark and skip further logic.
  if (rootElement.classList.contains('force-dark')) {
    rootElement.setAttribute('data-theme', 'dark');
    // Optionally, update assets to dark theme:
    updateThemeAssets('dark');
    // And return early to avoid adding any event listeners:
    return;
  }

  // 1. Check for saved theme in localStorage
  const savedTheme = localStorage.getItem('theme');

  // 2. Check user's OS preference (returns true if OS is set to dark mode)
  const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

  // Function to set the theme data attribute
  function applyTheme(theme) {
    if (theme === 'dark') {
      rootElement.setAttribute('data-theme', 'dark');
      themeToggle.forEach((toggle) => {toggle.classList.remove('light')});
      themeToggle.forEach((toggle) => {toggle.classList.add('dark')});
    } else {
      rootElement.setAttribute('data-theme', 'light');
      themeToggle.forEach((toggle) => {toggle.classList.remove('dark')});
      themeToggle.forEach((toggle) => {toggle.classList.add('light')});
    }
    // Save to localStorage and set videos
    localStorage.setItem('theme', theme);
    updateThemeAssets(theme);
  }

  // Decide on initial theme
  if (savedTheme) {
    // If there's a saved theme, use it
    applyTheme(savedTheme);
  } else {
    // Otherwise, use the user's OS preference
    applyTheme(userPrefersDark ? 'dark' : 'light');
  }

  // 3. On button click, toggle between dark and light
  themeToggleBtn.forEach((button) => {
    button.addEventListener('click', () => {
      const currentTheme = rootElement.getAttribute('data-theme');
      const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
      applyTheme(newTheme);
    });
  })
});

function updateThemeAssets(theme) {
  // Select all elements that might need theme-based switching
  const elements = document.querySelectorAll('.js-theme-asset');

  elements.forEach(el => {
    // Get the data attributes (paths) for dark and light
    const darkSrc = el.dataset.srcDark;
    const lightSrc = el.dataset.srcLight;

    // If it's a VIDEO element
    if (el.tagName.toLowerCase() === 'video') {
      // Determine screen size (adjust breakpoint as needed)
      const isMobile = window.matchMedia('(max-width: 836px)').matches;

      // Update each <source> element based on its media attribute:
      const sources = el.querySelectorAll('source');
      sources.forEach(source => {
        const media = source.getAttribute('media');
        let newSrc = '';
        if (media.includes('max-width')) {
          newSrc = (theme === 'dark') ? el.dataset.srcDarkMobile : el.dataset.srcLightMobile;
        } else if (media.includes('min-width')) {
          newSrc = (theme === 'dark') ? el.dataset.srcDarkDesktop : el.dataset.srcLightDesktop;
        }
        source.src = newSrc;
      });

      // Update the poster attribute (fallback image) based on screen size and theme
      const newPoster = (theme === 'dark')
          ? (isMobile ? el.dataset.posterDarkMobile : el.dataset.posterDarkDesktop)
          : (isMobile ? el.dataset.posterLightMobile : el.dataset.posterLightDesktop);
      if (newPoster) {
        el.poster = newPoster;
      }

      // Reload the video to apply new sources/poster
      el.load();

      // Wait for enough data to load before trying to play the video
      el.addEventListener('loadeddata', function onReady() {
        el.removeEventListener('loadeddata', onReady);
        el.play().catch(err => {
          if (err.name === 'AbortError') {
            // The load was aborted, likely due to rapid theme switching. Safe to ignore.
          } else {
            console.error('Video play() failed:', err);
          }
        });
      });
    }

    // If it's an IMG element
    else if (el.tagName.toLowerCase() === 'img') {
      el.src = (theme === 'dark') ? darkSrc : lightSrc;
    }

    // If it's a PICTURE element
    else if (el.tagName.toLowerCase() === 'picture') {
      // Usually <picture> has one or more <source> children + an <img> fallback
      // For simplicity, let's assume there's exactly one <source> child to update:
      const sourceEl = el.querySelector('source');
      if (!sourceEl) return;
      // For <picture>/<source>, we typically update `srcset`
      sourceEl.srcset = (theme === 'dark') ? darkSrc : lightSrc;

      // Optionally, you might want to also update the <img> fallback inside <picture>
      const imgFallback = el.querySelector('img');
      if (imgFallback && imgFallback.dataset.srcLight && imgFallback.dataset.srcDark) {
        // If you want the <img> fallback to switch too, you could store data attributes:
        // data-src-light and data-src-dark on the <img> as well
        const imgDark = imgFallback.dataset.srcDark;
        const imgLight = imgFallback.dataset.srcLight;
        imgFallback.src = (theme === 'dark') ? imgDark : imgLight;
      }
    }

    // If you had other element types (e.g., background images in a DIV),
    // you could add more "else if" checks here.
  });
}


// expand mobile nav accordion
document.querySelectorAll('.js-mobile-menu-expand').forEach((button) => {
  button.addEventListener('click', () => {
    button.classList.toggle('active');
    const items = button.nextElementSibling;

    items.style.height = button.classList.contains('active')
      ? `${items.scrollHeight}px`
      : 0;
  });
});

// load hero video
document.querySelectorAll('.js-hero-video').forEach((video) => {
  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
      lazyLoadVideo(video);
    }, 500);
  });
});
