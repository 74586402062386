export function lazyLoadVideo(video, callback) {
  const sources = video.children;
  Array.from(sources).forEach((source) => {
    if (source.getAttribute('data-src'))
      source.setAttribute('src', source.getAttribute('data-src'));
  });

  video.addEventListener('loadeddata', () => {
    video.classList.remove('hidden');
    if (typeof callback === 'function') callback();
  });

  video.load();
}
