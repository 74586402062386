import ScrollOut from 'scroll-out'; // https://scroll-out.github.io/guide.html#api
import { emitter } from '@/helpers/emitter';

document.addEventListener('DOMContentLoaded', () => {
    ScrollOut({
        onShown: function (element) {
            emitter.emit('scroll:onShow', element);
        },
        onHidden: function (element) {
            emitter.emit('scroll:onHide', element);
        },
    });
});
