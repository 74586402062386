export const smFunc = new (function () {
  this.getJson = (url, method, data) =>
    fetch(url, {
      method: method,
      mode: 'cors',
      cache: 'no-cache',
    headers: {
        'Content-Type': 'application/json',
    },
    redirect: 'follow',
    body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error();
      }
    });
})();
